<template>
  <div class="card">
    <div class="card-body row">
      <div class="col-12">
        <div class="card">
          <h5 class="card-header h3 mb-0">
            {{ getTrans("messages.wireTransfer_info") }}
          </h5>
          <div class="card-body">
            <div class="col-12 wireTransfer" v-if="type == 'bankTransfer'">
              <div class="row">
                {{ getTrans("messages.package_price") }}: {{ validPrice }}
              </div>
              <div class="row">
                {{ getTrans("messages.fee") }}: {{ validFee }}
              </div>
              <div class="row">
                <b>{{ getTrans("messages.total_to_pay") }}: {{ validTotal }}</b>
              </div>
              <div class="row">
                <h4>{{ getTrans("messages.payment_instructions") }}</h4>
              </div>
              <div class="row">
                <div class="alert alert-primary">
                  <b>{{ getTrans("messages.payment_instructions_detailed") }}</b>
                </div>
              </div>
              <div class="row">
                <h4>{{ getTrans("messages.account_information") }}</h4>
              </div>
              <div class="row">
                <div class="p-2">
                  <!-- eslint-disable -->
                    INTERNATIONAL TRADE OF AMERICA SAS
                    <br />BANCO DE OCCIDENTE
                    <br />010-98388-0
                    <br />CUENTA AHORROS
                    <br />Nit: 900972959-2
                    <br />
                    <!-- eslint-enable -->
                </div>
              </div>
            </div>

            <div class="col-12 wireTransfer" v-else>
              <div class="row">
                {{ getTrans("messages.package_price") }}: {{ validPrice }}
              </div>
              <div class="row">
                {{ getTrans("messages.fee") }}: {{ validFee }}
              </div>
              <div class="row">
                <b>{{ getTrans("messages.total_to_pay") }}: {{ validTotal }}</b>
              </div>
              <div class="row pt-4">
                <h4>{{ getTrans("messages.payment_instructions") }}</h4>
              </div>
              <div class="row">
                <div class="alert alert-primary">
                  <b>{{
                    getTrans("messages.payment_instructions_detailed")
                  }}</b>
                </div>
              </div>
              <div class="row">
                <h4>{{ getTrans("messages.account_information") }}</h4>
              </div>
              <div class="row">
                <div class="p-2">
                  <!-- eslint-disable -->
                    Bank name: DNB Bank ASA<br/>
                    Address: Dronning Eufemias Gate 30,<br/>
                    zip: 0120<br/>
                    city: Oslo<br/>
                    country: Norway<br/><br/>
                    Swift: DNBANOKKXXX<br/>
                    Account Number: 12500558979<br/>
                    IBAN: NO7212500558979<br/>
                    Routing Number: NO72 1250 0558 979<br/>
                    <!-- eslint-enable -->
                </div> 
              </div>
            </div>
          </div>
          <div class="card-footer">
            <base-button type="primary" @click="paid"  :disabled="!price">{{
              getTrans("messages.i_have_paid")
            }}</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import stringFormatter from "@/mixins/StringFormatter.vue";
export default {
  name: "WireTransfer",
  mixins: [stringFormatter],
  components: {},
  props: ["type", "price", "fee", "total"],
  computed: {
    validPrice() {
      return this.toDollarsWithDigits(this.price);
    },
    validFee() {
      return this.toDollarsWithDigits(this.fee);
    },
    validTotal() {
      return this.toDollarsWithDigits(this.total);
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    paid() {
      this.$store.dispatch("Payment/sendWiretransfer", this.price)
        .then(() => {
          return this.$swal.fire({
            icon: "success",
            text: this.getTrans(
              this.type == "banktransfer"
                ? "messages.banktransfer_success"
                : "messages.wire_success"
            ),
          });
        }).catch(() => {
          this.$swal.fire({
            icon: "error",
            text: this.getTrans("error.wiretransfer"),
          });
        });
    },
  },
};
</script>
