<script>
export default {
  methods: {
    capitalizeFirstLetter(string) {
      try {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } catch (e) {
        return "";
      }
    },
    toDollars(value) {
      if (!value) value=0;
      return Number(value).toLocaleString("en-US", {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: "USD"
      });
    },
    toDollarsWithDigits(value) {
      if (!value) value=0;
      return Number(value).toLocaleString("en-US", {
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "USD"
      });
    },
    tokensBalance(value) {
      return Math.floor(value).toLocaleString("en-US")
    },
    usdToTokens(value) {
      return this.tokensBalance(value) * 10;
    },
    limitText(text, length) {
      let smaller = text.substring(0, length)
      return smaller.length < text.length ? smaller+'...' : text;
    }
  }
};
</script>
